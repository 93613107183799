<template>
  <div
    class="horizontal-report-menu"
  >
    <Playlist
      v-if="loadedData"
      :popup-visible="popupVisible"
      @update:popupVisible="popupVisible = $event"
      :is-sum="false"
    />
    <Playlist
      v-if="loadedData"
      :popup-visible="sumPopupVisible"
      @update:popupVisible="sumPopupVisible = $event"
      :is-sum="true"
    />
    <div
      class="flex"
    >
      <!-- Go back button -->
      <div
        class="flex items-center back-button"
        v-if="!fromOtp"
      >
        <router-link
          class="flex items-center"
          :to="isTeam ? `/teams/${campaignId}/scoreboard` : { name: 'Scoreboard', params: { campaignId, page: 0 } }"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              viewBox="0 0 24 24"
              fill="white"
            >
              <path
                d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z"
              />
            </svg>
          </div>
          <span class="text-span">Scoreboard</span>
        </router-link>
      </div>
    </div>
    <div
      class="flex"
      @mouseleave="hoverIndex = null"
      style="min-width: 0;"
    >
      <div
        v-if="reportResultPages.length > 1"
        :class="showLeftArrow ? 'scroll-button' : 'disabled-scroll-button'"
        style="padding-right: 0.5rem; margin-right: 0.5rem"
        @click="showLeftArrow ? $emit('input', value - 1) : ''"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="21"
          :fill="showLeftArrow ? 'white' : '#ccc'"
        >
          <path
            d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
          />
        </svg>
        <span class="scroll-text">Prev</span>
      </div>
      <!-- Report test result pages  -->
      <div
        v-for="page,i in reportResultPages"
        :key="'vertical-menu-link'+i"
        class="menu-link"
        :style=" {'background-color': i + 4 == value ? 'rgb(66, 82, 103)' : null}"
        style="min-width: 0"
        @click="$emit('input', i + 4 )"
        @mouseenter="hoverIndex = i"
      >
        <div class="tab-truncate">
          {{ page.navigationTitle }}
        </div> <div
          v-if=" i+1 !== reportResultPages.length"
          :style="(hoverIndex != i && hoverIndex != i + 1 && i + 4 !== value) ? null : {'opacity' : '0'}"
          class="tab-border"
        />
      </div>
      <div
        v-if="reportResultPages.length > 1"
        :class="showRightArrow ? 'scroll-button' : 'disabled-scroll-button'"
        style="padding-left: 0.5rem; margin-left: 0.5rem"
        @click="showRightArrow ? $emit('input', value + 1) : ''"
      >
        <span class="scroll-text">Next</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="21"
          :fill="showRightArrow ? 'white': '#ccc'"
        ><path
          d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
        /></svg>
      </div>
    </div>
    <div
      class="menu-link flex"
      :style="value < 4 ? {'background-color': '#425267'} : null"
      @click.stop="showAboutModal = true"
    >
      <!-- Gather all pages with general info  -->
      <div
        style="cursor: pointer; position: relative; display: inline-block;"
      >
        About

        <ul
          v-show="showAboutModal"
          class="dropdown"
        >
          <li
            v-for="page,i in reportInfoPages"
            class="dropdown-item"
            :key="'info-page-link'+i"
            :style="{'text-decoration': i == value ? 'underline' : null}"
            @click="$emit('input', i )"
          >
            {{ page.navigationTitle }}
          </li>
        </ul>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="21"
        fill="white"
      ><path d="M7,10L12,15L17,10H7Z" /></svg>
    </div>
    <!-- vertical dots -->
    <div
      class="menu-link flex"
      @click.stop="showMoreModal = true"
    >
      <div
        style="cursor: pointer; position: relative; display: inline-block;"
        :style="{'text-decoration' : value < 4 ? 'underline' : null}"
      >
        <svg
          width="21"
          height="21"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
          />
        </svg>
        <ul
          v-show="showMoreModal"
          class="dropdown"
          style="position: absolute; right: 0; left: auto;"
        >
          <li
            v-for="item, i in menuActions"
            :key="'menu-action'+i"
            class="dropdown-item"
            @click="item.action"
          >
            <a
              class="flex items-center"
            ><svg

               viewBox="0 0 24 24"
               stroke-width="1.5"
               :stroke="item.stroke ||'currentColor'"
               :fill="item.fill||'none'"
               height="21"
               style="margin-right: 1rem"
             >
               <path
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 :d="item.svgPath"
               />
             </svg>
              {{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import Playlist from '@/components/Playlist.vue'

export default {
  components: {
    Playlist
  },
  methods: {
    copyCurrentUrl () {
      const currentUrl = window.location.href

      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          this.linkIsCopied = true

          setTimeout(() => {
            this.linkIsCopied = false
          }, 2000)
        })
        .catch(() => {
          console.error('Unable to copy to clipboard')
        })
    },
    closeModals () {
      this.showAboutModal = false
      this.showMoreModal = false
    }
  },

  props: {
    reportResultPages: {
      type: Array,
      required: true
    },
    reportInfoPages: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    allowRawDataReport: {
      type: Boolean,
      required: false,
      default: false
    },
    fromOtp: {
      type: Boolean,
      required: false,
      default: false
    },
    closeReportModals: {
      type: Boolean,
      required: true,
      default: false
    },
    loadedData: {
      type: Boolean,
      required: true,
      default: false
    },
    isTeam: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showAboutModal: false,
      showMoreModal: false,
      popupVisible: false,
      sumPopupVisible: false,
      linkIsCopied: false,
      hoverIndex: null
    }
  },
  computed: {
    menuActions () {
      const arr = [
        { include: true, title: 'Download report', action: () => { this.$emit('download', false) }, svgPath: 'M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3' },
        { include: true, title: 'Download report (anonymized)', action: () => { this.$emit('download', true) }, svgPath: 'M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3' },
        { include: !this.fromOtp && !this.isTeam, title: 'Share with email', action: () => { this.$emit('share') }, svgPath: 'M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75' },
        { include: this.fromOtp, title: this.copyAction.title, action: () => { this.copyCurrentUrl() }, stroke: 'none', fill: 'currentColor', svgPath: this.copyAction.svgPath },
        { include: !this.isTeam, title: 'Result, short video', action: () => { this.sumPopupVisible = true }, svgPath: 'M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z' },
        { include: !this.isTeam, title: 'Result/recommendations, long video', action: () => { this.popupVisible = true }, svgPath: 'M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5' },
        { include: !this.isTeam, title: 'Full tutorial, video & text', action: () => { window.open('https://demo.capaciotalent.com/docs/', '_blank') }, svgPath: 'M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z' },
        { include: true, title: 'Report demo video', action: () => { window.open('https://youtu.be/Qmkf-5w4YB4', '_blank') }, svgPath: 'M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z' },
        { include: this.allowRawDataReport, title: 'Download raw data report (ADMIN)', action: () => { this.$emit('downloadRawDataReport') }, svgPath: 'M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3' }
      ]
      const filteredArr = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].include) {
          filteredArr.push(arr[i])
        }
      }
      return filteredArr
    },
    copyAction () {
      if (this.linkIsCopied) {
        return { title: 'Copied!', svgPath: 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' }
      } else {
        return { title: 'Copy link', svgPath: 'M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z' }
      }
    },
    showLeftArrow () {
      return this.value > 4 && this.value < 11
    },
    showRightArrow () {
      return this.value > 3 && this.value < 10
    }
  },
  watch: {
    closeReportModals () {
      this.closeModals()
    }
  }
}

</script>

  <style lang="scss">
  @import "../HorizontalReportMenu";
  </style>
